import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const ProductRollTemplate = ({ data, postCount, filterTag }) => {
  const { edges: posts } = data.allMarkdownRemark;

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const filteredPosts = postCount ? posts.slice(0, postCount) : posts;

  const shouldDisplay = screenWidth > 1000;
  const shouldDisplayMobile = screenWidth < 1000;

  const getFilteredPosts = () => {
    if (filterTag) {
      return filteredPosts.filter(({ node: post }) => {
        const tags = post.frontmatter.tags || [];
        return tags.indexOf(filterTag) !== -1;
      });
    } else {
      return filteredPosts;
    }
  };

  const displayedPosts = getFilteredPosts();
  // console.log(displayedPosts)
  return (
    <div className="columns is-multiline">
      {displayedPosts.map(({ node: post }) => (
        <div className="is-parent column is-6" key={post.id}>
          <article
            className={`blog-list-item tile is-child box notification ${
              post.frontmatter.featuredpost ? 'is-featured' : ''
            }`}
          >
            <div className="media">
              <div className="media-left">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                    width:
                      post.frontmatter.featuredimage.childImageSharp
                        .gatsbyImageData.width,
                    height:
                      post.frontmatter.featuredimage.childImageSharp
                        .gatsbyImageData.height,
                  }}
                />
              </div>
              <div className="media-content">
                <div className="content">
                  <p className="post-meta">
                    <Link className="title is-size-4" to={post.fields.slug}>
                      {post.frontmatter.name}
                    </Link>
                    <span> &bull; </span>
                  </p>
                  {shouldDisplay && (
                    <div className="description-price">
                      <p className="description">
                        {post.frontmatter.description.slice(0, 100)} ...
                      </p>
                      <h2 className="price">
                        £{post.frontmatter.unit_amount / 100}
                      </h2>
                      <Link className="button" to={post.fields.slug}>
                        See more →
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {shouldDisplayMobile && (
              <p>
                {post.frontmatter.description.slice(0, 100)} ...
                <h2 className="price">£{post.frontmatter.unit_amount / 100}</h2>
                <Link className="button" to={post.fields.slug}>
                  See more →
                </Link>
              </p>
            )}
          </article>
        </div>
      ))}
    </div>
  );
};

ProductRollTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  postCount: PropTypes.number,
  filterTag: PropTypes.string,
};

const ProductRoll = ({ postCount, filterTag }) => (
  <StaticQuery
    query={graphql`
      query ProductRollQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "product-post" } } }
          sort: { fields: [frontmatter___name], order: DESC }
        ) {
          edges {
            node {
              excerpt(pruneLength: 10)
              id
              fields {
                slug
              }
              frontmatter {
                unit_amount
                description
                name
                tags
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(width: 120, quality: 100, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <ProductRollTemplate
        data={data}
        postCount={postCount}
        filterTag={filterTag}
        count={count}
      />
    )}
  />
);

ProductRoll.propTypes = {
  postCount: PropTypes.number,
  filterTag: PropTypes.string,
};

export default ProductRoll;

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { Link } from "gatsby";
import AOS from 'aos';
import 'aos/dist/aos.css'
const ContentLayout = ({ Image, Tagline, Text,imagePosition,ContentLinkText }) => {
  console.log(ContentLinkText)
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    
    <>
    {imagePosition === "right" ? (
        <div class="container "  style={{width:'80%'}}>
          <div className="columns is-vcentered" style={{paddingTop: 75, paddingBottom:75}}>
              <div className="column  " >
                <div style={{padding: 10}} >
                  <h2 className="has-text-weight-semibold is-size-5">
                    {Tagline}
                  </h2>
                </div>
                <div style={{padding: 10}}  >
                  <p>{Text}</p>
                </div>
                {ContentLinkText && <div>{ContentLinkText}</div>}
              </div>
            <div className="tile is-parent" >
              <article className="tile is-child">
                <PreviewCompatibleImage imageInfo={Image} />
              </article>
              </div>
            </div>
        </div>
        ) : (
        <div class="container "  style={{width:'80%'}}>
          <div className="columns is-vcentered" style={{paddingTop: 75, paddingBottom:75}}>
            <div className="tile is-parent" >
            <article className="tile is-child">
            <PreviewCompatibleImage imageInfo={Image} />
            </article>
            </div>
            <div className="column  " >
              <div style={{padding: 10}} >
              <h2 className="has-text-weight-semibold is-size-5">
              {Tagline}
                </h2>
              </div>
              <div style={{padding: 10}} >
                <p>{Text}</p>
              </div>
              {ContentLinkText && <div style={{padding: 10}}><Link to="/contact">{ContentLinkText}</Link></div>}
            </div>
          </div>
        </div>
        )
}
</>
)
}

ContentLayout.propTypes = {
  Image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  Tagline: PropTypes.string,
  Text: PropTypes.string,
  imagePosition: PropTypes.string,
};

export default ContentLayout;
